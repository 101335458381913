<template>
  <!-- 授信管理 -->
  <div class="hangOutManagementPageBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :operation-button-width="220"
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 签署确认弹窗 -->
    <PdfContract
      :sign-or-not="pdfContractConfig.signOrNot"
      :visible.sync="pdfContractConfig.visible"
      :url="pdfContractConfig.url"
    />
    <!-- 申请用印的弹窗 -->
    <!-- <Dialog
      ref="dialog"
      :dialog-width="'460px'"
      :edit-form-data="editFormData"
      :add-and-edit-form-item="addAndEditFormItem"
      @getFormData="getFormData"
    /> -->
  </div>
</template>

<script>
import { roundUpToInteger } from '@/utils/util'
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import PdfContract from '@/components/PdfContract'
import { agentCreditInfoList } from '@/api/generationMining.js'
export default {
  components: { FormSearch, PdfContract, Table, Pagination },
  data() {
    return {
      editFormData: {},
      pdfContractConfig: {},
      addAndEditFormItem: [{
        label: '审批类型',
        type: 'radio',
        child: [{ value: 1, label: '系统审批' }, { value: 2, label: '钉钉审批' }],
        value: 'oaType'
      }],
      itemData: [
        { label: '额度编号', prop: 'creditNo', width: 200 },
        { label: '代采商', prop: 'fundsCmpName', width: 240 },
        { label: '经销商', prop: 'cmpName', width: 240 },
        // { label: '统一社会信用代码', prop: 'cmpUnicode', width: 200 },
        // { label: '联系人', prop: 'contacts', width: 120 },
        // { label: '联系电话', prop: 'contactsPhone', width: 180 },
        // { label: '保证金比例(%)', prop: 'depositRate', width: 180 },
        // { label: '所在地址', prop: 'placeProvince,placeCity,placeTown', type: 'dictionaries', dictType: ['PROVINCE', 'CITY', 'AREA'], width: 180 },
        { label: '授信额度(万元)', prop: 'creditTotalAmt', width: 120, type: 'money' },
        { label: '已用额度(元)', prop: 'creditUsedAmt', width: 120, type: 'money' },
        { label: '冻结额度(元)', prop: 'creditFrzAmt', width: 120, type: 'money' },
        { label: '可用额度(元)', prop: 'creditAvlbAmt', width: 120, type: 'money' },
        { label: '额度到期日', prop: 'validDate', width: 180 },
        { label: '创建时间', prop: 'createTime', width: 140 },
        { label: '状态', prop: 'creditStatus', width: 180, child: this.$store.getters.getDictionaryItem('CREDIT_STATUS') }
      ],
      formItemArr: [
        { type: 'input', label: '额度编号', value: 'creditNo' },
        { type: 'input', label: '代采商', value: 'fundsCmpName' },
        { type: 'input', label: '经销商', value: 'cmpName' },
        { type: 'select', label: '状态', value: 'creditStatus', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('CREDIT_STATUS') }
      ],
      loading: false,
      total: 0,
      operationButton: [
        // { bType: 'primary', label: '审核', handleEvent: this.skipUrl, child: [{ val: 'status' }] },
        // { bType: 'primary', label: '用印申请', val: 'creditStatus', num: 5, handleEvent: this.usingApplication },
        { bType: 'primary', label: '查看协议', handleEvent: this.signContract },
        // { bType: 'primary', label: '拒绝签署', val: 'creditStatus', num: 6, handleEvent: this.refusedSign },
        // { bType: 'primary', label: '额度调整', val: 'creditStatus', num: 0, handleEvent: this.skipUrl, withParameters: 'adjustment' },
        { bType: 'primary', label: '详情', handleEvent: this.skipUrl, withParameters: 6 }
      ],
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        custCmpName: ''
      }
    }
  },
  methods: {
    skipUrl(row, index) {
      this.$router.push({ path: '/crmManagement/creditDetails', query: { id: row.id, index }})
    },
    // 点击签署按钮
    signContract(row) {
      this.pdfContractConfig = {
        visible: true,
        url: row.proxyContractUrl,
        id: row.id
      }
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      agentCreditInfoList(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.creditTotalAmt = roundUpToInteger(Number(item.creditTotalAmt) / 10000)
        })
        this.total = res.data.total
      })
    }
  }
}
</script>
